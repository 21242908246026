<template>
  <div class="StreamMedia">
    <div :class="{ isAvailable: stream.isAvailable }">
      <!-- <h4 class="name-tag">{{ id }}</h4> -->
      <!-- <h4 class="name-tag">
        {{ remoteParticipant.displayName }} | {{ stream.mediaStreamType }}
      </h4> -->
      <div :id="`${id}-${stream.mediaStreamType}-${stream.id}`" class="video-tag"></div>
    </div>
  </div>
</template>

<script>
import { Renderer } from "@azure/communication-calling";

export default {
  props: {
    remoteParticipant: {
      type: Object,
      default: () => ({}),
    },
    stream: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      id: "UnknowUser",
      state: {},
    };
  },
  watch: {
    remoteParticipant: {
      immediate: true,
      handler(val) {
        this.id = this.getIdentifierText(val?.identifier);
        this.state = {
          isAvailable: this.stream?.isAvailable || false,
          isSpeaking: false,
        };
      },
    },
  },
  async mounted() {
    console.log("StreamMedia stream", this.stream);
    console.log("StreamMedia remoteParticipant", this.remoteParticipant);

    this.remoteParticipant.on("isSpeakingChanged", () => {
      this.setState({ isSpeaking: this.remoteParticipant.isSpeaking });
    });

    console.log("StreamMedia", this.stream, this.id);
    let renderer = new Renderer(this.stream);
    let view;
    let videoContainer;

    const renderStream = async () => {
      if (!view) {
        view = await renderer.createView();
      }
      // TODO: same remote user with different stream.id
      videoContainer = document.getElementById(
        `${this.id}-${this.stream.mediaStreamType}-${this.stream.id}`
      );
      if (!videoContainer) return;
      videoContainer.hidden = false;
      if (!videoContainer.hasChildNodes()) {
        videoContainer.appendChild(view.target);
      }
    };

    this.stream.on("isAvailableChanged", async () => {
      console.log(
        `stream=${this.stream.type}, availabilityChanged=${this.stream.isAvailable}`
      );
      if (this.stream.isAvailable) {
        this.state.isAvailable = true;
        await renderStream();
      } else {
        if (videoContainer) {
          videoContainer.hidden = true;
        }
        this.state.isAvailable = false;
      }
    });

    if (this.stream.isAvailable) {
      this.state.isAvailable = true;
      await renderStream();
    }
  },
  methods: {
    getIdentifierText(identifier) {
      return identifier.microsoftTeamsUserId || "Unknwon Identifier";
    },
  },
};
</script>

<style lang="scss">
.StreamMedia {
  height: 100%;
  width: 100%;
  // min-width: 360px;
}
</style>
