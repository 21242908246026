<template>
  <div class="acs-header">
    <v-layout class="pa-4 align-center">
      <v-row>
        <v-col cols="4" xs="4">
          <img src="@/assets/logo.png" alt="" />
        </v-col>
        <v-col cols="20" xs="8">
          <v-layout class="flex" justify-center align-center>
            <v-spacer></v-spacer>
            <div>
              <div v-show="isInited && isStarted && joinDialog.isCallSuccess">
                <v-btn
                  icon
                  :color="toggle.camera ? 'pink' : 'grey'"
                  @click="evtToggleCamera"
                >
                  <v-icon>mdi-video-outline</v-icon>
                </v-btn>
                <v-btn
                  icon
                  :color="toggle.microphone ? 'pink' : 'grey'"
                  @click="evtToggleMicrophone"
                >
                  <v-icon>mdi-microphone</v-icon>
                </v-btn>
                <v-btn v-if="false" icon color="pink">
                  <v-icon>mdi-volume-high</v-icon>
                </v-btn>
              </div>
            </div>

            <v-progress-circular
              v-if="!isInited"
              indeterminate
              color="primary"
            ></v-progress-circular>

            <div v-else>
              <template v-if="isStarted && joinDialog.isCallSuccess">
                <v-btn
                  color="pink"
                  dark
                  class="d-none d-sm-flex ml-4"
                  @click="evtEndCall"
                >
                  Leave
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
                <v-btn class="d-flex d-sm-none" icon color="pink">
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
              </template>

              <v-btn
                v-else
                color="pink"
                dark
                class="ml-4"
                @click="joinDialog.show = true"
              >
                Join
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-layout>
        </v-col>
      </v-row>
    </v-layout>

    <v-dialog v-model="joinDialog.show" max-width="500px">
      <v-card>
        <v-card-title>
          Join Meeting
          <v-spacer></v-spacer>

          <v-btn icon color="pink" @click="joinDialog.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="joinDialog.nickName"
                label="Nick Name"
              ></v-text-field>

              <v-text-field
                v-model="joinDialog.teamsLink"
                label="Teams Link"
                :error="joinDialog.error"
                :error-messages="joinDialog.errorMessage"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-progress-circular
            v-if="joinDialog.isLinkValidating"
            indeterminate
            color="primary"
          ></v-progress-circular>

          <v-btn v-else color="primary" text @click="evtJoinCall()"> Join </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isStarted: {
      type: Boolean,
      default: false,
    },
    isInited: {
      type: Boolean,
      default: false,
    },
    joinAction: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      joinDialog: {
        show: false,
        // teamsLink: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_Yjk4OGY1ZjYtMTEzMi00ZGJjLWEwOTMtYjk1M2EwZjkyM2M1%40thread.v2/0?context=%7b%22Tid%22%3a%2226e01746-9b0e-4dd7-a3f6-9b84b8fa4f39%22%2c%22Oid%22%3a%22020ff0eb-dceb-4c15-98d2-a50465a38d0f%22%7d",
        teamsLink: "",
        isLinkValidating: false,
        isCallSuccess: false,
        nickName: "Guest user",
      },
      toggle: {
        camera: false,
        microphone: true,
      },
    };
  },
  methods: {
    async evtJoinCall() {
      //   this.$emit("join", {
      //     teamsLink: this.joinDialog.teamsLink,
      //   });

      try {
        this.joinDialog.isLinkValidating = true;
        await this.joinAction({
          teamsLink: this.joinDialog.teamsLink,
          nickName: this.joinDialog.nickName,
        });
        this.joinDialog.show = false;
        this.joinDialog.isCallSuccess = true;
      } catch (error) {
        this.joinDialog.isCallSuccess = false;
        this.joinDialog.error = true;
        this.joinDialog.errorMessage = error.toString();
      } finally {
        this.joinDialog.isLinkValidating = false;
      }
    },
    evtEndCall() {
      location.reload();
    },
    evtToggleCamera() {
      this.toggle.camera = !this.toggle.camera;
      this.$emit("toggle", { type: "camera", value: this.toggle.camera });
    },
    evtToggleMicrophone() {
      this.toggle.microphone = !this.toggle.microphone;
      this.$emit("toggle", { type: "microphone", value: this.toggle.microphone });
    },
  },
};
</script>

<style lang="scss" scoped>
.acs-header {
  background-color: whitesmoke;
  height: 10vh;
  img {
    max-height: 3rem;
  }
}
</style>
