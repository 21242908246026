<template>
  <div>
    <!-- <AtgVcAzureAcs :token="token" /> -->
    <!-- <v-btn @click="evtCall">Start</v-btn> -->
    <VcAzureAcs style="height: 100vh" :token="token" :teamsMeetingLink="teamsLink" />
  </div>
</template>

<script>
// import VcAzureAcs from "@atg/vue-vc-azure-acs";
import VcAzureAcs from "@/components/AzureAcs/VcAzureAcs";

export default {
  components: {
    // AtgVcAzureAcs: VcAzureAcs.VcAzureAcs,
    VcAzureAcs,
  },
  data() {
    return {
      isStartCall: false,
      token: "",
      teamsLink: "",
    };
  },
  created() {
    this.evtCall();
  },
  methods: {
    async evtCall() {
      if (process.env.NODE_ENV === "development") {
        // this.teamsLink = `https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTZmNzlkYTUtZjQ3Yy00NTAxLTg3ZWQtNmNkNTdiZDdhMGY5%40thread.v2/0?context=%7b%22Tid%22%3a%2226e01746-9b0e-4dd7-a3f6-9b84b8fa4f39%22%2c%22Oid%22%3a%22020ff0eb-dceb-4c15-98d2-a50465a38d0f%22%7d`;
        const res = await fetch("https://atg-vc-api.azurewebsites.net/api/acs-token");
        const { token } = await res.json();
        this.token = token;
      } else {
        const { teamslink } = this.$route.params;
        this.teamsLink = decodeURIComponent(teamslink);
        const res = await fetch("/api/token");
        const { token } = await res.json();
        this.token = token;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
