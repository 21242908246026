<template>
  <div class="atg-acs-main">
    <Header
      :isInited="isInited"
      :isStarted="state.call ? true : false"
      :joinAction="evtStartCall"
      @toggle="evtToggle"
    />
    <div v-if="state.call">
      <MediaGallery
        ref="MediaGallery"
        :call="state.call"
        :device-manager="deviceManager"
      />
    </div>
  </div>
</template>

<script>
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { createClientLogger, setLogLevel } from "@azure/logger";
import { CallClient, LocalVideoStream } from "@azure/communication-calling";
import MediaGallery from "./MediaGallery.vue";
import Header from "./Header.vue";

export default {
  name: "VcAzureAcs",
  components: {
    MediaGallery,
    Header,
  },
  props: {
    token: {
      type: String,
      default: "",
    },
    displayName: {
      type: String,
      default: "ACS Guest User",
    },
    teamsMeetingLink: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isInited: false,
      tokenCredential: undefined,
      callClient: undefined,
      callAgent: undefined,
      deviceManager: undefined,
      state: {
        call: undefined,
      },
    };
  },
  watch: {
    token: {
      immediate: true,
      async handler(val) {
        if (!val) return;
        await this.initTokenCredential(val);
      },
    },
  },
  methods: {
    setState(stateObj) {
      const _this = this;
      Object.keys(stateObj).forEach((key) => {
        _this.state[key] = stateObj[key];
      });
    },
    async initTokenCredential(token) {
      this.isInited = false;
      if (!token) {
        console.log("invalid");
        return;
      }
      this.tokenCredential = new AzureCommunicationTokenCredential(token);
      this.isInited = true;
    },
    initCallClient() {
      if (this.callClient) return;
      this.callClient = new CallClient({ logger: this.initLogger() });
    },
    async initCallAgent(tokenCredential, displayName) {
      this.initCallClient();
      if (this.callAgent) return;
      const callAgent = await this.callClient.createCallAgent(tokenCredential, {
        displayName: displayName || this.displayName,
      });
      // update window scope
      window.callAgent = callAgent;
      this.callAgent = callAgent;
    },
    initLogger() {
      const logger = createClientLogger("ACS");
      setLogLevel("warning");
      logger.verbose.log = (...args) => {
        console.log(...args);
      };
      logger.info.log = (...args) => {
        console.info(...args);
      };
      logger.warning.log = (...args) => {
        console.warn(...args);
      };
      logger.error.log = (...args) => {
        console.error(...args);
      };
      return logger;
    },
    async initDeviceManager() {
      const deviceManager = await this.callClient.getDeviceManager();
      this.deviceManager = deviceManager;
      this.deviceManager = deviceManager;
      await this.deviceManager.askDevicePermission({ audio: true, video: true });
    },
    bindObserver() {
      window.callAgent.on("callsUpdated", (e) => {
        console.log(`callsUpdated, added=${e.added}, removed=${e.removed}`);

        e.added.forEach((call) => {
          if (this.state.call && call.isIncoming) {
            call.reject();
            return;
          }
          this.setState({ call: call, callEndReason: undefined });
        });

        e.removed.forEach((call) => {
          if (this.state.call && this.state.call === call) {
            if (this.state.call.callEndReason.code !== 0) {
              this.setState({
                callError: `Call end reason: code: ${this.state.call.callEndReason.code}, subcode: ${this.state.call.callEndReason.subcode}`,
              });
            }

            this.setState({ call: null, callEndReason: this.state.call.callEndReason });
          }
        });
      });
    },
    async joinTeamsMetting(meetingLink) {
      if (!meetingLink) return;
      window.callAgent.join({ meetingLink }, await this.getCallOptions());
    },
    async getCallOptions() {
      let callOptions = {
        videoOptions: {
          localVideoStreams: undefined,
        },
        audioOptions: {
          muted: false,
        },
      };

      // detect camera
      const cameraDevice = await this.deviceManager.getCameras()[0];
      if (!cameraDevice || cameraDevice.id === "camera:") {
        // showCameraNotFoundWarning
      } else if (cameraDevice) {
        this.setState({ selectedCameraDeviceId: cameraDevice.id });
        // overwrite callOptions
        const localVideoStream = new LocalVideoStream(cameraDevice);
        callOptions.videoOptions = { localVideoStreams: [localVideoStream] };
      }

      // detect speaker
      const speakerDevice = await this.deviceManager.getSpeakers()[0];
      if (!speakerDevice || speakerDevice.id === "speaker:") {
        this.setState({ showSpeakerNotFoundWarning: true });
      } else if (speakerDevice) {
        this.setState({ selectedSpeakerDeviceId: speakerDevice.id });
        // config deviceManager
        this.deviceManager.setSpeaker(speakerDevice);
      }

      // detect microphone
      const microphoneDevice = await this.deviceManager.getMicrophones()[0];
      if (!microphoneDevice || microphoneDevice.id === "microphone:") {
        this.setState({ showMicrophoneNotFoundWarning: true });
      } else {
        this.setState({ selectedMicrophoneDeviceId: microphoneDevice.id });
        // config deviceManager
        this.deviceManager.setMicrophone(microphoneDevice);
      }

      return callOptions;
    },
    async evtStartCall(opt) {
      const { teamsLink, nickName } = opt;

      await this.initCallAgent(this.tokenCredential, nickName);
      await this.initDeviceManager();
      this.bindObserver();

      if (teamsLink) {
        await this.joinTeamsMetting(teamsLink);
      }
    },
    async evtToggle({ type, value }) {
      if (type === "camera") {
        await this.$refs["MediaGallery"]?.handleLocalVideoOnOff(value);
      }
      if (type === "microphone") {
        if (value) await this.$refs["MediaGallery"]?.call.unmute();
        else await this.$refs["MediaGallery"]?.call.mute();
      }
    },
  },
};
</script>

<style lang="scss">
.atg-acs-main {
  height: 100%;
  background-color: black;
}
</style>
