<template>
  <div class="atg-acs-MediaGallery">
    <LocalMedia
      v-model="state.showLocalVideo"
      v-if="state.showLocalVideo"
      class="local-video"
      :selected-camera-device-id="selectedCameraDeviceId"
      :device-manager="deviceManager"
    />
    <RemoteMedia :streams="state.streams" />
  </div>
</template>

<script>
import { LocalVideoStream } from "@azure/communication-calling";
import RemoteMedia from "./RemoteMedia.vue";
import LocalMedia from "./LocalMedia.vue";

export default {
  components: {
    LocalMedia,
    RemoteMedia,
  },
  props: {
    call: {
      type: Object,
      default: () => ({}),
    },
    deviceManager: {
      type: Object,
      default: () => ({}),
    },
    selectedCameraDeviceId: {
      type: String,
      default: "",
    },
    selectedSpeakerDeviceId: {
      type: String,
      default: "",
    },
    selectedMicrophoneDeviceId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      callFinishConnectingResolve: undefined,
      state: {
        callState: this.call.state,
        callId: this.call.id,
        remoteParticipants: this.call.remoteParticipants,
        streams: [],
        videoOn: true,
        micMuted: false,
        onHold: this.call.state === "Hold",
        screenShareOn: this.call.isScreenShareOn,
        cameraDeviceOptions: [],
        speakerDeviceOptions: [],
        microphoneDeviceOptions: [],
        selectedCameraDeviceId: this.selectedCameraDeviceId,
        selectedSpeakerDeviceId: this.selectedSpeakerDeviceId,
        selectedMicrophoneDeviceId: this.selectedMicrophoneDeviceId,
        showSettings: false,
        showLocalVideo: false,
      },
    };
  },
  async mounted() {
    if (this.call) {
      const cameraDevices = await this.deviceManager.getCameras();
      const speakerDevices = await this.deviceManager.getSpeakers();
      const microphoneDevices = await this.deviceManager.getMicrophones();
      cameraDevices.map((cameraDevice) => {
        this.state.cameraDeviceOptions.push({
          key: cameraDevice.id,
          text: cameraDevice.name,
        });
      });
      speakerDevices.map((speakerDevice) => {
        this.state.speakerDeviceOptions.push({
          key: speakerDevice.id,
          text: speakerDevice.name,
        });
      });
      microphoneDevices.map((microphoneDevice) => {
        this.state.microphoneDeviceOptions.push({
          key: microphoneDevice.id,
          text: microphoneDevice.name,
        });
      });

      this.deviceManager.on("videoDevicesUpdated", (e) => {
        e.added.forEach((cameraDevice) => {
          this.state.cameraDeviceOptions.push({
            key: cameraDevice.id,
            text: cameraDevice.name,
          });
        });

        e.removed.forEach((removedCameraDevice) => {
          this.state.cameraDeviceOptions.forEach((value, index) => {
            if (value.key === removedCameraDevice.id) {
              this.state.cameraDeviceOptions.splice(index, 1);
              if (removedCameraDevice.id === this.state.selectedCameraDeviceId) {
                const cameraDevice = cameraDevices[0];
                this.setState({ selectedCameraDeviceId: cameraDevice.id });
              }
            }
          });
        });
      });

      this.deviceManager.on("audioDevicesUpdated", (e) => {
        e.added.forEach((audioDevice) => {
          if (audioDevice.deviceType === "Speaker") {
            this.state.speakerDeviceOptions.push({
              key: audioDevice.id,
              text: audioDevice.name,
            });
          } else if (audioDevice.deviceType === "Microphone") {
            this.state.microphoneDeviceOptions.push({
              key: audioDevice.id,
              text: audioDevice.name,
            });
          }
        });

        e.removed.forEach((removedAudioDevice) => {
          if (removedAudioDevice.deviceType === "Speaker") {
            this.state.speakerDeviceOptions.forEach((value, index) => {
              if (value.key === removedAudioDevice.id) {
                this.state.speakerDeviceOptions.splice(index, 1);
                if (removedAudioDevice.id === this.state.selectedSpeakerDeviceId) {
                  const speakerDevice = speakerDevices[0];
                  this.deviceManager.setSpeaker(speakerDevice);
                  this.setState({ selectedSpeakerDeviceId: speakerDevice.id });
                }
              }
            });
          } else if (removedAudioDevice.deviceType === "Microphone") {
            this.state.microphoneDeviceOptions.forEach((value, index) => {
              if (value.key === removedAudioDevice.id) {
                this.state.microphoneDeviceOptions.splice(index, 1);
                if (removedAudioDevice.id === this.state.selectedMicrophoneDeviceId) {
                  const microphoneDevice = microphoneDevices[0];
                  this.deviceManager.setMicrophone(microphoneDevice);
                  this.setState({ selectedMicrophoneDeviceId: microphoneDevice.id });
                }
              }
            });
          }
        });
      });

      const onCallStateChanged = () => {
        console.log("callStateChanged ", this.state.callState);
        this.setState({ callState: this.call.state });

        if (
          this.state.callState !== "None" &&
          this.state.callState !== "Connecting" &&
          this.state.callState !== "Incoming"
        ) {
          if (this.callFinishConnectingResolve) {
            this.callFinishConnectingResolve();
          }
        }
        if (this.state.callState === "Incoming") {
          this.selectedCameraDeviceId = cameraDevices[0]?.id;
          this.selectedSpeakerDeviceId = speakerDevices[0]?.id;
          this.selectedMicrophoneDeviceId = microphoneDevices[0]?.id;
        }
      };
      onCallStateChanged();
      this.call.on("callStateChanged", onCallStateChanged);

      this.call.on("callIdChanged", () => {
        console.log("callIdChanged ", this.call.id);
        this.setState({ callId: this.call.id });
      });

      this.call.on("isRecordingActiveChanged", () => {
        console.log("isRecordingActiveChanged ", this.call.isRecordingActive);
      });

      this.call.on("isMicrophoneMutedChanged", () => {
        this.setState({ micMuted: this.call.isMicrophoneMuted });
      });

      this.call.on("isScreenSharingOnChanged", () => {
        this.setState({ screenShareOn: this.call.isScreenShareOn });
      });

      this.call.remoteParticipants.forEach((rp) => this.subscribeToRemoteParticipant(rp));
      this.call.on("remoteParticipantsUpdated", (e) => {
        console.log(
          `Call=${this.call.callId}, remoteParticipantsUpdated, added=${e.added}, removed=${e.removed}`
        );
        e.added.forEach((p) => {
          console.log("participantAdded", p);
          this.subscribeToRemoteParticipant(p);
          this.setState({ remoteParticipants: this.call.remoteParticipants });
        });
        e.removed.forEach((p) => {
          console.log("participantRemoved", p);
          this.setState({ remoteParticipants: this.call.remoteParticipants });
        });
      });
    }
  },
  methods: {
    setState(stateObj) {
      const _this = this;
      Object.keys(stateObj).forEach((key) => {
        _this.state[key] = stateObj[key];
      });
    },

    subscribeToRemoteParticipant(participant) {
      participant.on("displayNameChanged", () => {
        console.log("displayNameChanged ", participant.displayName);
      });

      participant.on("participantStateChanged", () => {
        console.log(
          "participantStateChanged",
          participant.identifier.communicationUserId,
          participant.state
        );
        this.setState({ remoteParticipants: this.call.remoteParticipants });
      });

      const addToListOfAllParticipantStreams = (participantStreams) => {
        if (participantStreams) {
          let participantStreamTuples = participantStreams.map((stream) => {
            return { stream, participant };
          });
          const tuplesToAdd = [];
          participantStreamTuples.forEach((participantStreamTuple) => {
            if (
              !this.state.streams.find((v) => {
                return v === participantStreamTuple;
              })
            ) {
              tuplesToAdd.push(participantStreamTuple);
            }
          });
          this.setState({ streams: this.state.streams.concat(tuplesToAdd) });
        }
      };

      const removeFromListOfAllParticipantStreams = (participantStreams) => {
        if (participantStreams) {
          let participantStreamTuples = participantStreams.map((stream) => {
            return { stream, participant };
          });
          let arr = this.state.streams;
          arr.forEach((tuple, i) => {
            if (
              participantStreamTuples.find((v) => {
                return v === tuple;
              })
            ) {
              this.state.streams.splice(i);
            }
          });
          this.setState({ streams: arr });
        }
      };

      const handleVideoStreamsUpdated = (e) => {
        addToListOfAllParticipantStreams(e.added);
        removeFromListOfAllParticipantStreams(e.removed);
      };

      addToListOfAllParticipantStreams(participant.videoStreams);
      participant.on("videoStreamsUpdated", handleVideoStreamsUpdated);
    },

    async handleAcceptCall() {
      const cameraDevice = await this.deviceManager.getCameras()[0];
      let localVideoStream;
      if (!cameraDevice || cameraDevice.id === "camera:") {
        // this.props.onShowCameraNotFoundWarning(true);
      } else if (cameraDevice) {
        this.setState({ selectedCameraDeviceId: cameraDevice.id });
        localVideoStream = new LocalVideoStream(cameraDevice);
      }

      const speakerDevice = await this.deviceManager.getSpeakers()[0];
      if (!speakerDevice || speakerDevice.id === "speaker:") {
        // this.props.onShowSpeakerNotFoundWarning(true);
      } else if (speakerDevice) {
        this.setState({ selectedSpeakerDeviceId: speakerDevice.id });
        this.deviceManager.setSpeaker(speakerDevice);
      }

      const microphoneDevice = await this.deviceManager.getMicrophones()[0];
      if (!microphoneDevice || microphoneDevice.id === "microphone:") {
        // this.props.onShowMicrophoneNotFoundWarning(true);
      } else {
        this.setState({ selectedMicrophoneDeviceId: microphoneDevice.id });
        this.deviceManager.setMicrophone(microphoneDevice);
      }

      this.call
        .accept({
          videoOptions:
            this.state.videoOn && cameraDevice
              ? { localVideoStreams: [localVideoStream] }
              : undefined,
        })
        .catch((e) => console.error(e));
    },

    async handleVideoOnOff() {
      try {
        if (
          this.call.state === "None" ||
          this.call.state === "Connecting" ||
          this.call.state === "Incoming"
        ) {
          if (this.state.videoOn) {
            this.setState({ videoOn: false });
          } else {
            this.setState({ videoOn: true });
          }
          await this.watchForCallFinishConnecting();
          if (this.state.videoOn) {
            const cameraDeviceInfo = await this.deviceManager
              .getCameras()
              .find((cameraDeviceInfo) => {
                return cameraDeviceInfo.id === this.state.selectedCameraDeviceId;
              });
            debugger;

            this.call
              .startVideo(new LocalVideoStream(cameraDeviceInfo))
              .catch((error) => {
                console.error(error);
              });
          } else {
            this.call.stopVideo(this.call.localVideoStreams[0]).catch((error) => {
              console.error(error);
            });
          }
        } else {
          this.handleLocalVideoOnOff();
        }

        this.setState({ videoOn: this.call.localVideoStreams[0] ? true : false });
      } catch (e) {
        console.error(e);
      }
    },

    async handleLocalVideoOnOff(toggle) {
      this.setState({ showLocalVideo: toggle });

      if (toggle === false && this.call.localVideoStreams[0]) {
        await this.call.stopVideo(this.call.localVideoStreams[0]);
      } else if (toggle === true) {
        // const cameraDeviceInfo = (await this.deviceManager.getCameras()).find(
        //   (cameraDeviceInfo) => {
        //     return cameraDeviceInfo.id === this.state.selectedCameraDeviceId;
        //   }
        // );
        const cameraDeviceInfo = (await this.deviceManager.getCameras())[0];
        await this.call.startVideo(new LocalVideoStream(cameraDeviceInfo));
      }
    },

    async watchForCallFinishConnecting() {
      return new Promise((resolve) => {
        if (
          this.state.callState !== "None" &&
          this.state.callState !== "Connecting" &&
          this.state.callState !== "Incoming"
        ) {
          resolve();
        } else {
          this.callFinishConnectingResolve = resolve;
        }
      }).then(() => {
        this.callFinishConnectingResolve = undefined;
      });
    },

    async handleMicOnOff() {
      try {
        if (!this.call.isMicrophoneMuted) {
          await this.call.mute();
        } else {
          await this.call.unmute();
        }
        this.setState({ micMuted: this.call.isMicrophoneMuted });
      } catch (e) {
        console.error(e);
      }
    },

    async handleHoldUnhold() {
      try {
        if (this.call.state === "Hold") {
          this.call.unhold();
        } else {
          this.call.hold();
        }
      } catch (e) {
        console.error(e);
      }
    },

    async handleScreenSharingOnOff() {
      try {
        if (this.call.isScreenSharingOn) {
          await this.call.stopScreenSharing();
        } else {
          await this.call.startScreenSharing();
        }
        this.setState({ screenShareOn: this.call.isScreenSharingOn });
      } catch (e) {
        console.error(e);
      }
    },

    async cameraDeviceSelectionChanged(event, item) {
      const cameraDeviceInfo = await this.deviceManager
        .getCameras()
        .find((cameraDeviceInfo) => {
          return cameraDeviceInfo.id === item.key;
        });
      const localVideoStream = this.call.localVideoStreams[0];
      localVideoStream.switchSource(cameraDeviceInfo);
      this.setState({ selectedCameraDeviceId: cameraDeviceInfo.id });
    },

    async speakerDeviceSelectionChanged(event, item) {
      const speakerDeviceInfo = await this.deviceManager
        .getSpeakers()
        .find((speakerDeviceInfo) => {
          return speakerDeviceInfo.id === item.key;
        });
      this.deviceManager.setSpeaker(speakerDeviceInfo);
      this.setState({ selectedSpeakerDeviceId: speakerDeviceInfo.id });
    },

    async microphoneDeviceSelectionChanged(event, item) {
      const microphoneDeviceInfo = await this.deviceManager
        .getMicrophones()
        .find((microphoneDeviceInfo) => {
          return microphoneDeviceInfo.id === item.key;
        });
      this.deviceManager.setMicrophone(microphoneDeviceInfo);
      this.setState({ selectedMicrophoneDeviceId: microphoneDeviceInfo.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.atg-acs-MediaGallery {
  width: 100%;
  height: 90vh;
  background-color: black;
  min-width: 300px;
  min-height: 600px;
}
</style>
