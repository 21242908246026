<template>
  <div>
    <span
      class="name-tag"
      :class="{
        isSpeaking: participant.isSpeaking,
        isMuted: participant.isMuted,
      }"
    >
      <span class="pl-2">{{ participant.displayName }}</span>

      <v-btn
        v-if="!participant.videoStreams.reduce((a, b) => a.isAvailable || b.isAvailable)"
        icon
        dark
      >
        <v-icon>mdi-video-off-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="participant.videoStreams.reduce((a, b) => a.isAvailable || b.isAvailable)"
        icon
        dark
      >
        <v-icon>mdi-video-outline</v-icon>
      </v-btn>

      <v-btn v-if="participant.isMuted" icon dark>
        <v-icon>mdi-volume-off</v-icon>
      </v-btn>

      <v-btn v-if="!participant.isMuted && participant.isSpeaking" icon dark>
        <v-icon>mdi-volume-high</v-icon>
      </v-btn>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    participant: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.name-tag {
  position: absolute;
  background-color: white;
  padding: 0.3rem;
  opacity: 0.8;
  margin: 0.3rem;
  border-radius: 0.5rem;
  color: white;
  z-index: 10;
}

.name-tag.isSpeaking {
  background-color: green;
}

.name-tag.isMuted {
  background-color: red;
}
</style>
