<template>
  <div class="LocalMedia">
    <div id="localVideoRenderer"></div>
  </div>
</template>

<script>
import { LocalVideoStream, Renderer } from "@azure/communication-calling";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    deviceManager: {
      type: Object,
      default: () => ({}),
    },
    selectedCameraDeviceId: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.evtShowLocalCameraPreview();
      },
    },
  },
  async mounted() {},
  beforeDestroy() {
    // this.videoElm?.remove();
    this.renderer.dispose();
  },
  methods: {
    async evtShowLocalCameraPreview() {
      if (!this.value) return;
      if (this.selectedCameraDeviceId) {
        this.cameraDeviceInfo = await this.deviceManager
          .getCameras()
          .find((cameraDevice) => {
            return cameraDevice.id === this.selectedCameraDeviceId;
          });
      } else {
        this.cameraDeviceInfo = (await this.deviceManager.getCameras())[0];
      }
      const localVideoStream = new LocalVideoStream(this.cameraDeviceInfo);
      const renderer = new Renderer(localVideoStream);
      this.view = await renderer.createView();
      const targetContainer = document.getElementById("localVideoRenderer");
      this.videoElm = targetContainer.appendChild(this.view.target);

      this.renderer = renderer;
    },
  },
};
</script>

<style lang="scss" scoped>
.LocalMedia {
  position: absolute;
  bottom: calc(0.2rem);
  right: calc(0.5rem);
  max-width: 12rem;
  max-height: 10rem;
  // background-color: gray;
  border-radius: 0.5rem;
  z-index: 15;
}
</style>
