<template>
  <div class="RemoteMedia" v-if="streams.length">
    <div
      class="remote-participant"
      v-for="(p, index) in participants"
      :key="p.identifier.microsoftTeamsUserId || index"
    >
      <span
        v-if="!p.videoStreams.reduce((a, b) => a.isAvailable || b.isAvailable)"
        style="width: 100%"
      >
        <MediaNameTag :participant="p" />
        <v-img
          src="@/assets/avatar.png"
          alt=""
          style="background-color: white; opacity: 0.7"
          contain
          :aspect-ratio="16 / 9"
        />
      </span>
      <template v-for="(s, index) in p.videoStreams">
        <div v-if="s.isAvailable" class="participant-stream" :key="index">
          <MediaNameTag :participant="p" />
          <StreamMedia class="remote-video" :stream="s" :remote-participant="p" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import StreamMedia from "./StreamMedia.vue";
import MediaNameTag from "./MediaNameTag.vue";

export default {
  components: {
    StreamMedia,
    MediaNameTag,
  },
  props: {
    streams: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    participants() {
      return this.streams
        .filter((f) => f.stream.mediaStreamType.toLowerCase() === "video")
        .map((s) => {
          return s.participant;
        });
    },
  },
  mounted() {
    console.log("participants", this.participants);
  },
};
</script>

<style lang="scss" scoped>
.RemoteMedia {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  //   overflow-y: scroll;
  //   overflow: hidden;
  flex-wrap: wrap;
  //   border: 1rem solid whitesmoke;

  .remote-participant {
    // border: 0.2rem solid #e91e63;
    // min-width: 10rem;
    // min-height: 10rem;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    display: flex;
    flex-direction: row;

    .participant-stream {
      flex: 1;

      .remote-video {
        max-width: 100%;
        background-color: black;
        border-radius: 5px;
        z-index: 1;

        .video-tag {
          height: 100%;
        }
      }
    }
  }
}
</style>
